import { CatalogServiceDto } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WidgetViewModel } from '../../viewModelDeprecated/viewModel';
import { getResourceQueryParam } from '../../utils/queryParams/queryParams';
import { getLocationId } from '../../utils/getLocationId/getLocationId';
import { WidgetConfig } from '../../../legacy/types';
import { mapServiceToServiceV2 } from '../../utils/serviceDetailsDeprecated/serviceDetails';
import {
  DialogType,
  ServicePreferencesDialogViewModel,
} from '../../viewModelDeprecated/dialogViewModel/dialogViewModel';
import { PreselectedValues } from '@wix/bookings-services-preferences-modal/types';

export type openServicesPreferencesModalAction = ({
  service,
}: {
  service: CatalogServiceDto;
}) => Promise<void>;

export const createOpenServicesPreferencesModalAction = ({
  flowAPI,
  widgetViewModel,
  config,
  setProps,
}: {
  flowAPI: ControllerFlowAPI;
  widgetViewModel: WidgetViewModel;
  config: WidgetConfig;
  setProps: Function;
}): openServicesPreferencesModalAction => {
  return async ({ service }) => {
    const { createModalData } = await import(
      '@wix/bookings-services-preferences-modal/utils'
    );
    const { enrichModalData } = await import(
      '@wix/bookings-services-preferences-modal/controllerActions'
    );

    const queryParam = getResourceQueryParam(
      flowAPI.controllerConfig.wixCodeApi,
    );
    const locationId = getLocationId(flowAPI, widgetViewModel);
    const serviceV2 = mapServiceToServiceV2({ service });
    const preselectedValues: PreselectedValues = {
      locationId,
      ...(queryParam.resource
        ? {
            resources: {
              staff: [queryParam.resource],
            },
          }
        : {}),
    };

    const modalData = createModalData({
      regionalSettingsLocale: config.regionalSettings!,
      service: serviceV2,
      preselectedValues,
    });

    const servicePreferencesDialogViewModel: ServicePreferencesDialogViewModel =
      {
        isOpen: true,
        type: DialogType.ServicePreferences,
        data: modalData,
      };

    widgetViewModel.dialogViewModel = servicePreferencesDialogViewModel;
    setProps({
      widgetViewModel: {
        ...widgetViewModel,
      },
    });

    enrichModalData({
      service: serviceV2,
      httpClient: flowAPI.httpClient,
      getServicesPreferencesModalData: () =>
        servicePreferencesDialogViewModel.data,
      setServicesPreferencesModalData: (data) => {
        servicePreferencesDialogViewModel.data = data;
        setProps({ widgetViewModel: { ...widgetViewModel } });
      },
      experiments: flowAPI.experiments,
    });
  };
};
